<template>
  <!-- 培训协议 -->
  <div class="jxMyAssessment">
    <van-nav-bar
      title="我的考核表"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="container">
      <van-collapse v-model="activeName" accordion>
        <van-collapse-item title="2021" name="2021" size="large">
          <div class="cell_list">
            <van-cell
              title="202105考核周期（考核等级A）"
              label="2021-05-01"
              center
              is-link
              @click="visitKhDetail"
            ></van-cell>
            <van-cell
              title="202105考核周期（考核等级A）"
              label="2021-05-01"
              center
              is-link
            ></van-cell>
            <van-cell
              title="202105考核周期（考核等级A）"
              label="2021-05-01"
              center
              is-link
            ></van-cell>
            <van-cell
              title="202105考核周期（考核等级A）"
              label="2021-05-01"
              center
              is-link
            ></van-cell>
            <van-cell
              title="202105考核周期（考核等级A）"
              label="2021-05-01"
              center
              is-link
            ></van-cell>
          </div>
        </van-collapse-item>
        <van-collapse-item title="2022" name="2022" size="large">
          <div class="cell_list">
            <van-cell
              title="202105考核周期（考核等级A）"
              label="2021-05-01"
              center
              is-link
            ></van-cell>
            <van-cell
              title="202105考核周期（考核等级A）"
              label="2021-05-01"
              center
              is-link
            ></van-cell>
            <van-cell
              title="202105考核周期（考核等级A）"
              label="2021-05-01"
              center
              is-link
            ></van-cell>
            <van-cell
              title="202105考核周期（考核等级A）"
              label="2021-05-01"
              center
              is-link
            ></van-cell>
            <van-cell
              title="202105考核周期（考核等级A）"
              label="2021-05-01"
              center
              is-link
            ></van-cell>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "2021",
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("/home");
    },
    onClickRight() {},
    visitKhDetail() {
      this.$router.push("/jxMyAssessmentDetails");
    },
  },
};
</script>

<style lang="less" scoped>
.jxMyAssessment {
  height: 100vh;
  overflow: hidden;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .container {
    /deep/.van-collapse-item__content {
      padding: 0;
    }
    /deep/.van-collapse-item__title {
      .van-cell__title {
        font-weight: bold;
        font-size: 36px;
      }
    }
    .cell_list {
      /deep/.van-cell__title {
        font-size: 32px;
      }
      /deep/.van-cell__label {
        font-size: 24px;
      }
    }
  }
}
</style>